import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { openURLInNewTab } from "../../Utils";
import {
  faHome,
  faLaptop,
  faUserPlus,
  faPaperclip,
  faSearch,
  faEye,
  faCalendarCheck,
  faAddressBook,
  faBars,
  faNetworkWired,
  faBriefcase,
  faChartLine,
  faMagic,
  faTv,
  faUser,
  faChartPie,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showRevenue, setShowRevenue] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleRevenueOptions = () => {
    setShowRevenue(!showRevenue);
  };

  return (
    <div className="sidebar-container">
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
        <div className="menu-title">Accountability</div>
        <NavLink
          exact={true}
          activeClassName="is-active"
          to="/directorsDashboard"
        >
          <FontAwesomeIcon icon={faNetworkWired} />
          Directors
        </NavLink>
        <NavLink
          exact={true}
          activeClassName="is-active"
          to="/coordinatorsDashboard"
        >
          <FontAwesomeIcon icon={faBriefcase} />
          Coordinators
        </NavLink>
        <NavLink
          exact={true}
          activeClassName="is-active"
          to="/WebsiteDashboard"
        >
          <FontAwesomeIcon icon={faMagic} />
          Website
        </NavLink>
        <button className="sidebar-collapsible" onClick={toggleRevenueOptions}>
          <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faBars} />
          Revenue
        </button>
        {showRevenue && (
          <div id="revoptions">
            <a href="/salesstats">
              <FontAwesomeIcon icon={faTv} />
              TV Sales Stats
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY00000057sK2AQ/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              Front End Revenue
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY000000583w2AA/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              Back End Revenue
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY00000058462AA/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              AE Revenue
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY00000057so2AA/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              Partnership/Leads
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY000000584G2AQ/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              VP Revenue
            </a>
            <a
              href="/"
              onClick={(e) => {
                openURLInNewTab(
                  "https://gargleinc.lightning.force.com/lightning/r/Dashboard/01ZEY00000057t32AA/view?queryScope=userFolders"
                );
                e.preventDefault();
                return false;
              }}
            >
              Director Revenue
            </a>
          </div>
        )}
        <div className="menu-title">Revenue</div>
        <NavLink
          exact={true}
          activeClassName="is-active"
          to="/salesFlowDashboard"
        >
          <FontAwesomeIcon icon={faChartLine} />
          Sales Flow
        </NavLink>
        <NavLink
          exact={true}
          activeClassName="is-active"
          to="/salesLifecycleDashboard"
        >
          <FontAwesomeIcon icon={faChartLine} />
          Sales Lifecycle
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/cashFlow">
          <FontAwesomeIcon icon={faChartLine} />
          Cash Flow
        </NavLink>
        <div className="menu-title">Data Widget</div>
        <NavLink exact={true} activeClassName="is-active" to="/recordsList">
          <FontAwesomeIcon icon={faUser} />
          Export List
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/recordsOverview">
          <FontAwesomeIcon icon={faChartPie} />
          Overview
        </NavLink>
        <div className="menu-title">Views</div>
        <NavLink exact={true} activeClassName="is-active" to="/teams">
          <FontAwesomeIcon icon={faHome} />
          Teams
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/socialplus">
          <FontAwesomeIcon icon={faUserPlus} />
          Social+
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/website">
          <FontAwesomeIcon icon={faLaptop} />
          Website
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/ppc">
          <FontAwesomeIcon icon={faPaperclip} />
          PPC
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/seo">
          <FontAwesomeIcon icon={faSearch} />
          SEO
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/socialmedia">
          <FontAwesomeIcon icon={faEye} />
          Social Media
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/audit">
          <FontAwesomeIcon icon={faCalendarCheck} />
          Account Audit
        </NavLink>
        <NavLink exact={true} activeClassName="is-active" to="/content">
          <FontAwesomeIcon icon={faAddressBook} />
          Content
        </NavLink>
      </div>

      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
      </button>
    </div>
  );
};

export default Sidebar;
